<template>
  <v-container>
    <h2>{{ $t("navbar.vantaggi.title") }}</h2>
    <div>{{ $t("navbar.vantaggi.subtitle") }}</div>

    <div>
      <v-row @click="clicked">
        <v-col cols="12" md="6" sm="6" v-for="item in content" :key="item.id">
          <v-card elevation="3" class="vantaggi-card">
            <v-flex class="d-flex" style="height:100%;">
              <v-avatar class="align-self-center" tile>
                <img :src="item.featured_image_url" alt="Immagine vantaggi" />
              </v-avatar>
              <div class="flex-grow-1">
                <div>
                  <v-card-title>
                    <strong v-html="item.title.rendered"></strong>
                  </v-card-title>
                  <v-card-subtitle
                    v-html="item.content.rendered"
                  ></v-card-subtitle>
                </div>
                <v-spacer></v-spacer>

                <v-card-actions v-if="item.link">
                  <v-btn
                    class="discovery-more align-center"
                    color="primary"
                    depressed
                    :href="item.link"
                    >scopri di più</v-btn
                  >
                </v-card-actions>
              </div>
            </v-flex>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped>
img.size-full {
  width: 100%;
}
.v-avatar {
  margin: 5px;
  margin-left: 15px;
  height: 65px !important;
  min-width: 65px !important;
  width: 65px !important;
}
.vantaggi-card {
  height: 100%;
  border-radius: 8px;
}
.v-card__title {
  font-size: 1.05rem;
  line-height: 1.2em;
  word-break: break-word;
}
.v-card__actions {
  justify-content: flex-end;
  margin-top: -10px;
}
.v-card__subtitle {
  line-height: 1.3em;
  padding-top: 6px;
}
</style>
<script>
import page from "~/mixins/page";
import clickHandler from "~/mixins/clickHandler";
import CMSService from "~/service/cmService";
export default {
  name: "Vantaggi",
  mixins: [page, clickHandler],
  data() {
    return {
      content: []
    };
  },
  created() {
    this.fetchPage();
  },
  methods: {
    async fetchPage() {
      const res = await CMSService.getCustomPostByFilters("vantaggi");

      if (res && res.data && res.data.length) {
        this.content = res.data;
      }
      console.log(res);
    }
  }
};
</script>
